import {Box} from '@mantine/core';
import {WhiteBgButton} from '@packages/ui';
import {useEffect, useRef, useState} from 'react';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';
import {GoogleAdBannerName} from '../../../../config/constants/googleAdBanner';
import {useGoogleAdBanner} from '../../../../shared/services/useGoogleAdBanner';
import {useAuth} from '../../../../core/usecases/useAuth';
import {GoogleAdBanner} from '../../../../shared/components/Banner/GoogleAdBanner';
import {useInfiniteNotifications} from '../../../../core/repositories/notificationsRepository';
import {getTopBottomBannerPosition} from '../../../../shared/services/getTopBottomBannerPosition';
import {SpTopBottomBanner} from '../../../../shared/components/Banner/SpTopBottomBanner';
import {NotificationCard, type NotificationItem} from './NotificationCard';

type Props = Record<string, unknown>;

// ログイントップ全ての通知一覧
export const NotificationCardListPc: React.FC<Props> = () => {
  const {isPC} = useMediaQuery();
  const {accessToken} = useAuth();
  // DFPバナーのセット
  const googleAdBannerNames = [
    GoogleAdBannerName.A_TOP_PAGE_1,
    GoogleAdBannerName.A_TOP_PAGE_2,
  ];
  const devRef = useRef(null);
  const {setShouldRefetch} = useGoogleAdBanner(
    googleAdBannerNames,
    accessToken,
  );

  const [pageCountSp, setPageCountSp] = useState<number>(1);

  // PC用データ
  const {data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage} =
    useInfiniteNotifications({
      accessToken,
      limit: 20,
      forAllUsers: true,
    });

  useEffect(() => {
    if (data && !isLoading) {
      setShouldRefetch(true);
    }
  }, [data, isLoading]);

  return (
    <Box className="mt-24">
      {!isPC && <Box className="border-t border-solid border-gray-200" />}
      {data?.pages.map((page, i) => {
        return page.notifications.map((notification: NotificationItem, j) => {
          return (
            <div key={notification.id}>
              {i === 0 && j === 2 && isPC && (
                <div className="bg-white p-16 mb-16 flex justify-center items-center">
                  <GoogleAdBanner
                    googleAdBannerName={GoogleAdBannerName.A_TOP_PAGE_1}
                  />
                </div>
              )}
              {i === 0 && j === 6 && isPC && (
                <div className="bg-white p-16 mb-16 flex justify-center items-center">
                  <GoogleAdBanner
                    googleAdBannerName={GoogleAdBannerName.A_TOP_PAGE_2}
                  />
                </div>
              )}
              <NotificationCard notification={notification} />
              {!isPC && (
                <Box
                  className="border-t border-solid border-gray-200"
                  key={notification.id}
                />
              )}
            </div>
          );
        });
      })}
      {isPC &&
      data?.pages &&
      data?.pages?.length > 0 &&
      data?.pages?.[data.pages.length - 1]?.nextPage &&
      data?.pages.at(-1)?.nextPage !== 0 ? (
        <div className="mt-16" ref={devRef}>
          <WhiteBgButton
            label="もっと見る"
            isDisabled={isLoading}
            isPrimary={false}
            isLarge={true}
            onClick={async () => {
              await fetchNextPage();
            }}
            className="w-[80%] pc:w-[100%] mx-auto"
          />
        </div>
      ) : null}
    </Box>
  );
};

export const NotificationCardListSp: React.FC<Props> = () => {
  const {isPC} = useMediaQuery();
  const {accessToken} = useAuth();
  // DFPバナーのセット
  const googleAdBannerNames = [
    GoogleAdBannerName.A_TOP_PAGE_1,
    GoogleAdBannerName.A_TOP_PAGE_2,
  ];
  const {setShouldRefetch} = useGoogleAdBanner(
    googleAdBannerNames,
    accessToken,
  );

  // SP用データ
  const {
    data: dataSp,
    isLoading: isLoadingSp,
    fetchNextPage: fetchNextPageSp,
    hasNextPage: hasNextPageSp,
    isFetchingNextPage: isFetchingNextPageSp,
  } = useInfiniteNotifications({
    accessToken,
    limit: 20,
    forAllUsers: true,
    withoutCategories: ['community'],
  });

  useEffect(() => {
    if (dataSp && !isLoadingSp) {
      setShouldRefetch(true);
    }
  }, [dataSp, isLoadingSp]);

  return (
    <Box className="mt-24">
      {!isPC && <Box className="border-t border-solid border-gray-200" />}
      {dataSp?.pages.map((page, i) => {
        return (
          <div key={`page-sp-${i}`}>
            {page.notifications.map((notification: NotificationItem, j) => {
              const bannerPosition = getTopBottomBannerPosition(i * 8 + j);
              return (
                <div key={notification.id}>
                  {bannerPosition && (
                    <div className=" w-full pt-4 px-20 pb-20 flex justify-center">
                      <SpTopBottomBanner
                        accessToken={accessToken}
                        type={bannerPosition}
                      />
                    </div>
                  )}
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
      {!isPC &&
        dataSp?.pages &&
        dataSp?.pages.length > 0 &&
        dataSp?.pages?.[dataSp.pages.length - 1]?.nextPage &&
        dataSp?.pages.at(-1)?.nextPage !== 0 && (
          <div className="mt-16">
            <WhiteBgButton
              label="もっと見る"
              isDisabled={isLoadingSp}
              isPrimary={false}
              isLarge={true}
              onClick={async () => {
                await fetchNextPageSp();
              }}
              className="w-[80%] pc:w-[100%] mx-auto"
            />
          </div>
        )}
    </Box>
  );
};
